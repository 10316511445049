import axios from "../../lib/axios";

export async function get(slug, contact) {
    const response = await axios.post('/signature/heirs/' + slug + '/' + contact, {});
    return response.data;
}

export async function create(slug, contact, data) {
    data._method = 'PUT';
    const response = await axios.post('/signature/heirs/' + slug + '/' + contact, data);
    return response.data;
}