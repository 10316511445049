import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../../../components/Layout/Layout';
import Loading from "../../../components/Loading";
import {getAll as getAllUsers, destroy as destroyUser} from '../../../services/Apis/Admin/Users.service';
import DataTable from 'react-data-table-component';

function Users() {

    const [users, setUsers] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(true);

    const columns = [
        {
            name: 'First Name',
            selector: (row: any) => row.first_name,
            width: '22%'
        },
        {
            name: 'Last Name',
            selector: (row: any) => row.last_name,
            width: '22%'
        },
        {
            name: 'Email',
            selector: (row: any) => row.email,
        },
        {
            name: 'Andet',
            selector: (row: any) => <>
                <Link to={'/admin/users/'+row.id+'/edit'} className={`small-btn edit`} title={'Rediger'}></Link>
                <button type={"button"} onClick={() => deleteUser(row.id)} className={`small-btn delete`} title={'Slet'}></button>
            </>,
        },
    ];

    const deleteUser = async (userId: string) => {
        const response = await destroyUser(userId);
        await getUsers(1);
    }

    const getUsers = async (page: number) => {
        setIsLoading(true);

        const response = await getAllUsers(page, perPage);

        setUsers(response.data.data);
        setTotalRows(response.data.pagination.total);
        setIsLoading(false);
    };

    const handlePageChange = async (page: number) => {
        await getUsers(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {

    };

    useEffect(() => {
        (async () => {
            getUsers(1);
        })();
    }, [])

  return (
      <Layout>
          <>
              <Row className={`row-flex row-flex-wrap`}>
                    <Col xl={12}>
                        <div className="arv-top white-box nopad">
                            <div className="h-box-title">Users</div>
                            <div className="arv-top-btn"><Link to={'/admin/users/create'} className={`blue-btn`}>+ Opret User</Link></div>
                        </div>
                    </Col>
              </Row>
              <Row>
                  <Col xl={12}>
                      <div className="white-box arv-form-box">
                          <DataTable
                              title=""
                              columns={columns}
                              data={users}
                              progressPending={isLoading}
                              pagination
                              paginationServer
                              paginationTotalRows={totalRows}
                              onChangeRowsPerPage={handlePerRowsChange}
                              onChangePage={handlePageChange}
                              paginationComponentOptions={{noRowsPerPage: true}}
                          />
                      </div>
                  </Col>
              </Row>
          </>
      </Layout>
  );
}

export default Users;
