import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from "react-router-dom";
import routes from "./routes/routes";
import AuthProvider from 'react-auth-kit';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/app.scss';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import createStore from 'react-auth-kit/createStore';

const store = createStore({
    debug: false,
    authName:'_auth',
    authType:'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: false,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <AuthProvider store={store}>
          <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']} minBreakpoint="xxs">
              <RouterProvider router={routes} />
          </ThemeProvider>
      </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
