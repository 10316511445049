import React, {useEffect} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AgreementContactSchema from "../../../../services/Schemas/Admin/AgreementContactSchema";
import { ReactComponent as EditIcon } from "../../../../assets/images/icon-edit-2.svg";
import { create as createContact, update as updateContact } from '../../../../services/Apis/Admin/AgreementContacts.service';
import InputMask from 'react-input-mask';

function AgreementContact(props: any) {

    const { register, handleSubmit, formState:{ errors }, setValue, watch } = useForm({
        resolver: yupResolver(AgreementContactSchema),
        defaultValues: {
            email: props.values.email,
            first_name: props.values.first_name,
            last_name: props.values.last_name,
            cprnr: props.values.cprnr != null ? props.values.cprnr : '',
            address: props.values.address,
            zipcode: props.values.zipcode,
            city: props.values.city,
            phone_number: props.values.phone_number,
            relation: props.values.relation
        }
    });

    const handleContact = async (data: any) => {
        if (props.values.id === undefined) {
            const response = await createContact(props.agreement.agreement_id, data);
        } else {
            const response = await updateContact(props.agreement.agreement_id, props.values.id, data);
        }
        props.handleReload();
    }

    return (
        <>
            <form onSubmit={handleSubmit(handleContact)}>
                <div className="white-box arv-form-box">
                    <div className="form-box-middle text-center no-pad"> { props.title }</div>

                    <div className="form-box-top small-width">

                    </div>

                    <div className="hadnling-form">
                        <div className="form-group">
                            <label>Fornavn</label>
                            <input type="text" className="form-control" {...register("first_name")} />
                            <div className="invalid-feedback-error">{errors?.first_name?.message?.toString()}</div>
                        </div>
                        <div className="form-group">
                            <label>Efternavn</label>
                            <input type="text" className="form-control" {...register("last_name")} />
                            <div className="invalid-feedback-error">{errors?.last_name?.message?.toString()}</div>
                        </div>
                        <div className="form-group">
                            <label>Relation</label>
                            <select className="form-select" {...register("relation")}>
                                <option value={""}>Relation</option>
                                <option value={"mor"}>Mor</option>
                                <option value={"far"}>Far</option>
                                <option value={"soster"}>Søster</option>
                                <option value={"bror"}>Bror</option>
                                <option value={"datter"}>Datter</option>
                                <option value={"son"}>Søn</option>
                                <option value={"spouse"}>Ægtefælle</option>
                                <option value={"samlever"}>Samlever</option>
                                <option value={"bedstemor"}>Bedstemor</option>
                                <option value={"bedstefar"}>Bedstefar</option>
                                <option value={"barnebarn"}>Barnebarn</option>
                                <option value={"oldebarn"}>Oldebarn</option>
                                <option value={"tante"}>Tante</option>
                                <option value={"onkel"}>Onkel</option>
                                <option value={"faetter"}>Fætter</option>
                                <option value={"kusine"}>Kusine</option>
                                <option value={"nevo"}>Nevø</option>
                                <option value={"niece"}>Niece</option>
                                <option value={"svigermor"}>Svigermor</option>
                                <option value={"svigerfar"}>Svigerfar</option>
                                <option value={"svigerson"}>Svigersøn</option>
                                <option value={"svigerdatter"}>Svigerdatter</option>
                                <option value={"halvsoster"}>Halvsøster</option>
                                <option value={"halvbror"}>Halvbror</option>
                                <option value={"ven"}>Ven</option>
                                <option value={"andet"}>Andet</option>
                            </select>
                            <div className="invalid-feedback-error">{errors?.relation?.message?.toString()}</div>
                        </div>
                        <div className="form-group">
                            <label>CPR-nr.</label>
                            <InputMask mask="999999-9999" value={ watch('cprnr') } maskChar={null} className="form-control with-icon" {...register("cprnr")} />
                            <div className="invalid-feedback-error">{errors?.cprnr?.message?.toString()}</div>
                        </div>
                        <div className="form-group">
                            <label>Adresse</label>
                            <input type="text" className="form-control" {...register("address")} />
                            <div className="invalid-feedback-error">{errors?.address?.message?.toString()}</div>
                        </div>
                        <div className="form-group">
                            <label>Post nr.</label>
                            <input type="text" className="form-control" {...register("zipcode")} />
                            <div className="invalid-feedback-error">{errors?.zipcode?.message?.toString()}</div>
                        </div>
                        <div className="form-group">
                            <label>By</label>
                            <input type="text" className="form-control" {...register("city")} />
                            <div className="invalid-feedback-error">{errors?.city?.message?.toString()}</div>
                        </div>
                        <div className="form-group">
                            <label>Tlf. nr.</label>
                            <input type="tel" className="form-control" {...register("phone_number")} />
                            <div className="invalid-feedback-error">{errors?.phone_number?.message?.toString()}</div>
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" className="form-control" {...register("email")} />
                            <div className="invalid-feedback-error">{errors?.email?.message?.toString()}</div>
                        </div>

                        {props.values.id !== undefined &&
                            <div className="form-group text-center"><button type={"submit"} className="gray-btn"><EditIcon /> Rediger</button></div>
                        }

                        {props.values.id === undefined &&
                            <div className="form-group text-center"><button type={"submit"} className="blue-btn">Gem oplysninger</button></div>
                        }
                    </div>
                </div>
            </form>
        </>
    )
}

export default AgreementContact;