import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LayoutWithoutHeader from "../../../components/FrontLayout/LayoutWithoutHeader";
import contactInformationSchema from "../../../services/Schemas/Front/Agreement/ContactInformationSchema";
import Container from "react-bootstrap/Container";
import styles from "../../../styles/steps.module.scss";
import { useNavigate } from 'react-router-dom';
import {get as getAgreement} from "../../../services/Apis/Front/Agreements.service";
import Loading from "../../../components/Loading";
import IAgreement from "../../../interfaces/IAgreement";
import InputMask from "react-input-mask";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import IUser from "../../../interfaces/IUser";
import {update as updateAgreement} from "../../../services/Apis/Front/Agreements.service";

function ContactInformation() {

    const { register, watch, setValue, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(contactInformationSchema)
    });

    const navigate = useNavigate();
    let { agreementId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const auth = useAuthUser<IUser>();

    useEffect(() => {
        (async () => {
            const response = await getAgreement(agreementId);
            setTimeout(() => {
                setFormValues(response);
            }, 100);
            setIsLoading(false);
        })();

        document.body.classList.add('white-background');
    }, []);

    const setFormValues = (agreement: IAgreement) => {
        let contactPerson = Object.values(agreement.contactPersons).filter(e => e.is_main_contact === true);

        if (contactPerson.length) {
            setValue('first_name', contactPerson[0].first_name);
            setValue('last_name', contactPerson[0].last_name);
            setValue('relation', contactPerson[0].relation);
            setValue('cprnr', contactPerson[0].cprnr === null ? '' : contactPerson[0].cprnr);
            setValue('address', contactPerson[0].address);
            setValue('city', contactPerson[0].city);
            setValue('zipcode', contactPerson[0].zipcode);
            setValue('phone_number', contactPerson[0].phone_number);
            setValue('email', contactPerson[0].email);
        } else {
            setValue('email', auth ? auth.email : '');
            setValue('first_name', auth ? auth.first_name : '');
            setValue('last_name', auth ? auth.last_name : '');
        }
    }

    const handleFormSubmission = async (data: any) => {
        data.step = 'contact';

        try {
            const response = await updateAgreement(agreementId, data);
            navigate('/aftale/'+ agreementId +'/afdobe-information');
        } catch (err) {
            console.log(err);
        }
    }

  return (
      <LayoutWithoutHeader>
          <>
              {isLoading &&
                  <Loading/>
              }
              {!isLoading &&
                  <>
                  <div className={styles['q-step-row']}>
                      <Container className={styles['container']}>
                          <div className={styles['q-step-small-container']}>
                              <form onSubmit={handleSubmit(handleFormSubmission)}>
                                  <div className={`${styles['q-step-title']} text-left`}>Først skal vi bruge kontakt info på dig.</div>
                                  <p className={`text-center`}>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>
                                  <Row>
                                      <Col md={6} sm={12}>
                                          <div className={styles['form-group']}>
                                              <input type="text" className={`form-control ${styles['form-control']}`} placeholder="Fornavn" {...register("first_name")} />
                                              <div className={styles['invalid-field-error']}>{errors?.first_name?.message}</div>
                                          </div>
                                      </Col>
                                      <Col md={6} sm={12}>
                                          <div className={styles['form-group']}>
                                              <input type="text" className={`form-control ${styles['form-control']}`} placeholder="Efternavn" {...register("last_name")} />
                                              <div className={styles['invalid-field-error']}>{errors?.last_name?.message}</div>
                                          </div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md={12} sm={12}>
                                          <div className={styles['form-group']}>
                                              <input type="text" className={`form-control ${styles['form-control']}`} placeholder="Telefon Nr." {...register("phone_number")} />
                                              <div className={styles['invalid-field-error']}>{errors?.phone_number?.message}</div>
                                          </div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md={12} sm={12}>
                                          <div className={styles['form-group']}>
                                              <input type="email" className={`form-control ${styles['form-control']}`} placeholder="Email" {...register("email")} disabled={true} />
                                              <div className={styles['invalid-field-error']}>{errors?.email?.message}</div>
                                          </div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md={12} sm={12}>
                                          <div className={styles['form-group']}>
                                              <select
                                                  className={`form-select ${styles['form-control']}`} {...register("relation")}>
                                                  <option value={''}>Din relation til afdøde</option>
                                                  <option value={"mor"}>Mor</option>
                                                  <option value={"far"}>Far</option>
                                                  <option value={"soster"}>Søster</option>
                                                  <option value={"bror"}>Bror</option>
                                                  <option value={"datter"}>Datter</option>
                                                  <option value={"son"}>Søn</option>
                                                  <option value={"spouse"}>Ægtefælle</option>
                                                  <option value={"samlever"}>Samlever</option>
                                                  <option value={"bedstemor"}>Bedstemor</option>
                                                  <option value={"bedstefar"}>Bedstefar</option>
                                                  <option value={"barnebarn"}>Barnebarn</option>
                                                  <option value={"oldebarn"}>Oldebarn</option>
                                                  <option value={"tante"}>Tante</option>
                                                  <option value={"onkel"}>Onkel</option>
                                                  <option value={"faetter"}>Fætter</option>
                                                  <option value={"kusine"}>Kusine</option>
                                                  <option value={"nevo"}>Nevø</option>
                                                  <option value={"niece"}>Niece</option>
                                                  <option value={"svigermor"}>Svigermor</option>
                                                  <option value={"svigerfar"}>Svigerfar</option>
                                                  <option value={"svigerson"}>Svigersøn</option>
                                                  <option value={"svigerdatter"}>Svigerdatter</option>
                                                  <option value={"halvsoster"}>Halvsøster</option>
                                                  <option value={"halvbror"}>Halvbror</option>
                                                  <option value={"ven"}>Ven</option>
                                                  <option value={"andet"}>Andet</option>
                                              </select>
                                              <div
                                                  className={styles['invalid-field-error']}>{errors?.relation?.message}</div>
                                          </div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md={12} sm={12}>
                                          <div className={styles['form-group']}>
                                              <InputMask className={`form-control ${styles['form-control']}`} mask="999999-9999" maskChar={null} {...register("cprnr")} placeholder="Dit CPR-nr." />
                                              <div className={styles['invalid-field-error']}>{errors?.cprnr?.message}</div>
                                          </div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md={12} sm={12}>
                                          <div className={styles['form-group']}>
                                              <input type="text" className={`form-control ${styles['form-control']}`}
                                                     placeholder="Adresse" {...register("address")} />
                                              <div
                                                  className={styles['invalid-field-error']}>{errors?.address?.message}</div>
                                          </div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md={6} sm={12}>
                                          <div className={styles['form-group']}>
                                              <input type="text" className={`form-control ${styles['form-control']}`} placeholder="Post nr" {...register("zipcode")} />
                                              <div className={styles['invalid-field-error']}>{errors?.zipcode?.message}</div>
                                          </div>
                                      </Col>
                                      <Col md={6} sm={12}>
                                          <div className={styles['form-group']}>
                                              <input type="text" className={`form-control ${styles['form-control']}`} placeholder="By" {...register("city")} />
                                              <div className={styles['invalid-field-error']}>{errors?.city?.message}</div>

                                          </div>
                                      </Col>
                                  </Row>
                                  <div className={styles['q-steps-btn']}>
                                      <button type="submit" className={`blue-btn ${styles['blue-btn']}`}>VIDERE  👉</button>
                                  </div>
                              </form>
                          </div>
                      </Container>
                  </div>
                  <div className={styles['q-step-footer']}>
                      <Container>
                          <div className={styles['step-back']}>
                              <button
                                  onClick={() => navigate('/aftale/' + agreementId + '/start')}>Tilbage
                              </button>
                          </div>
                      </Container>
                  </div>
                  </>
              }
          </>
      </LayoutWithoutHeader>
  );
}

export default ContactInformation;