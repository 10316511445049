import React, { useState, useEffect } from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LayoutWithoutHeader from "../../../components/FrontLayout/LayoutWithoutHeader";
import Container from "react-bootstrap/Container";
import styles from "../../../styles/steps.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import estateInformationSchema from "../../../services/Schemas/Front/Agreement/EstateInformationSchema";
import {get as getAgreement} from "../../../services/Apis/Front/Agreements.service";
import IAgreement from "../../../interfaces/IAgreement";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import {update as updateAgreement} from "../../../services/Apis/Front/Agreements.service";
import Loading from "../../../components/Loading";

function EstateInformation() {

    const navigate = useNavigate();
    let { agreementId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [spouseDepartedDate, setSpouseDepartedDate] = useState<Date | undefined>();

    const { register, watch, setValue, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(estateInformationSchema)
    });

    useEffect(() => {
        (async () => {
            const response = await getAgreement(agreementId);
            setTimeout(() => {
                setFormValues(response);
            }, 100);
            setIsLoading(false);
        })();

        document.body.classList.add('white-background');
    }, []);

    const handleSpouseDepartedDate = (date: Date | undefined) => {
        setSpouseDepartedDate(date);
        setValue('spouse_departed_date', date);
    }

    const setFormValues = (agreement: IAgreement) => {
        setValue('heirs', agreement.heirs);
        setValue('have_will', agreement.have_will);
        setValue('left_company', agreement.left_company);
        setValue('spouse_details', ((agreement.spouse_name !== null && agreement.spouse_name !== '') ? 'yes' : ((agreement.spouse_name === null ? '' : 'no'))));
        setValue('spouse_name', agreement.spouse_name);
        setValue('spouse_cprnr', agreement.spouse_cprnr === null ? '' : agreement.spouse_cprnr);

        if (agreement.spouse_departed_date != undefined) {
            setSpouseDepartedDate(new Date(agreement.spouse_departed_date));
            setValue('spouse_departed_date', new Date(agreement.spouse_departed_date));
        }
    }

    const handleFormSubmission = async (data: any) => {
        data.step = 'estate';

        try {
            const response = await updateAgreement(agreementId, data);
            navigate('/aftale/'+ agreementId +'/sign-document');
        } catch (err) {
            console.log(err);
        }
    }

  return (
      <LayoutWithoutHeader>
          <>
          {isLoading &&
              <Loading/>
          }
          {!isLoading &&
              <>
              <div className={styles['q-step-row']}>
                  <Container className={styles['container']}>
                      <div className={styles['q-step-small-container']}>
                          <form onSubmit={handleSubmit(handleFormSubmission)}>
                              <div className={`${styles['q-step-title']} text-left`}>Informationer omkring dødsbo</div>
                              <p className={`text-center`}>Duis aute irure dolor in reprehenderit in voluptate velit esse
                                  cillum
                                  dolore eu fugiat nulla pariatur</p>

                              <Row>
                                  <Col md={12} sm={12}>
                                      <div className={styles['form-group']}>
                                          <select
                                              className={`form-select ${styles['form-control']}`} {...register("heirs")}>
                                              <option value={""}>Antal Arvinger</option>
                                              <option value={1}>1</option>
                                              <option value={2}>2</option>
                                              <option value={3}>3</option>
                                              <option value={4}>4</option>
                                              <option value={5}>5</option>
                                              <option value={6}>6</option>
                                              <option value={7}>7</option>
                                              <option value={8}>8</option>
                                              <option value={9}>9</option>
                                              <option value={10}>10</option>
                                              <option value={11}>11</option>
                                              <option value={12}>12</option>
                                              <option value={13}>13</option>
                                              <option value={14}>14</option>
                                              <option value={15}>15</option>
                                          </select>
                                          <div className={styles['invalid-field-error']}>{errors?.heirs?.message}</div>
                                      </div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md={12} sm={12}>
                                      <div className={styles['form-group']}>
                                          <select
                                              className={`form-select ${styles['form-control']}`} {...register("have_will")}>
                                              <option value={''}>Efterlod den aføde et testamente?</option>
                                              <option value={'false'}>Nej</option>
                                              <option value={'true'}>Ja</option>
                                          </select>
                                          <div className={styles['invalid-field-error']}>{errors?.have_will?.message}</div>
                                      </div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md={12} sm={12}>
                                      <div className={styles['form-group']}>
                                          <select
                                              className={`form-select ${styles['form-control']}`} {...register("left_company")}>
                                              <option value={''}>Efterlod den aføde et selskab?</option>
                                              <option value={'no'}>Nej</option>
                                              <option value={'person_left_company'}>Drev Ved Dødsfald Personligt Ejet Virksomhed</option>
                                              <option value={'first_person_left_company'}>Første Afdøde Drev Ved Dødsfald Personligt Ejet Virksomhed</option>
                                          </select>
                                          <div
                                              className={styles['invalid-field-error']}>{errors?.left_company?.message}</div>
                                      </div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md={12} sm={12}>
                                      <div className={styles['form-group']}>
                                          <select
                                              className={`form-select ${styles['form-control']}`} {...register("spouse_details")}>
                                              <option value={''}>Tidligere afdøde ægtefælle - længstlevende</option>
                                              <option value={'yes'}>Ja</option>
                                              <option value={'no'}>Nej</option>
                                          </select>
                                          <div
                                              className={styles['invalid-field-error']}>{errors?.spouse_details?.message}</div>
                                      </div>
                                  </Col>
                              </Row>
                              { watch('spouse_details', '') === 'yes' &&
                                  <>
                                      <div className={`${styles['q-step-title']} text-left`}>Længstlevende/Tidligere afdøde ægtefælle</div>
                                      <Row>
                                          <Col md={12} sm={12}>
                                              <div className={styles['form-group']}>
                                                  <input type="text" className={`form-control ${styles['form-control']}`} placeholder="Navn" {...register("spouse_name")} />
                                                  <div
                                                      className={styles['invalid-field-error']}>{errors?.spouse_name?.message}</div>
                                              </div>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col md={12} sm={12}>
                                              <div className={styles['form-group']}>
                                                  <InputMask className={`form-control ${styles['form-control']}`} mask="999999-9999" maskChar={null} {...register("spouse_cprnr")} placeholder={"CPR-nr."} />
                                                  <div className={styles['invalid-field-error']}>{errors?.spouse_cprnr?.message}</div>
                                              </div>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col md={12} sm={12}>
                                              <div className={styles['form-group']}>
                                                  <input type="hidden"
                                                         className="form-control" {...register("spouse_departed_date")} />
                                                  <DatePicker isClearable className={`form-control ${styles['form-control']}`}
                                                              selected={spouseDepartedDate}
                                                              onChange={(date: Date) => handleSpouseDepartedDate(date)}
                                                              dateFormat={'dd/MM/yyyy'} placeholderText={"Død den"} />
                                                  <div
                                                      className={styles['invalid-field-error']}>{errors?.spouse_departed_date?.message}</div>
                                              </div>
                                          </Col>
                                      </Row>
                                  </>
                              }
                              <div className={styles['q-steps-btn']}>
                                  <button type="submit" className={`blue-btn ${styles['blue-btn']}`}>GEM & FORTSÆT 👉</button>
                              </div>
                          </form>
                      </div>
                  </Container>
              </div>
                  <div className={styles['q-step-footer']}>
                  <Container>
                      <div className={styles['step-back']}>
                          <button onClick={() => navigate('/aftale/' + agreementId + '/afdobe-information')}>Tilbage</button>
                      </div>
                  </Container>
                  </div>
              </>
          }
          </>
      </LayoutWithoutHeader>
  );
}

export default EstateInformation;
