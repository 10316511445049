import * as yup from "yup";

const agreementSchema = yup.object({
    contactPerson: yup.object().shape({
        first_name: yup.string().trim().required('Please enter first name.'),
        last_name: yup.string().trim().required('Please enter last name.'),
        cprnr: yup.string().trim().required('Please enter valid CPR-Nr.').matches(/^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{2}[-]?\d{4}$/gm, 'Please enter valid CPR-Nr.'),
        address: yup.string().trim().required('Please enter address.'),
        zipcode: yup.string().trim().required('Please enter zip code.'),
        city: yup.string().trim().required('Please enter city.'),
        phone_number: yup.string().trim().required('Please enter phone number.'),
        email: yup.string().trim().required('Please enter email address.').email('Please enter valid email address.'),
        relation: yup.string().trim().required('Please select relation.')
    }),
    // case_no: yup.string().trim().required('Please enter case no.'),
    case_no: yup.string().trim(),
    departed_first_name: yup.string().trim().required('Please enter first name.'),
    departed_last_name: yup.string().trim().required('Please enter last name.'),
    departed_date: yup.date('Please select departed date.').required('Please select departed date.').typeError('Please select departed date.'),
    departed_cprnr: yup.string().trim().required('Please enter valid CPR-Nr.').matches(/^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{2}[-]?\d{4}$/gm, 'Please enter valid CPR-Nr.'),
    heirs: yup.number().typeError('Please enter number of heirs.').required('Please enter number of heirs.'),
    amount: yup.number().typeError('Please enter amount.').required('Please enter amount.'),
    departed_address: yup.string().trim().required('Please enter address.'),
    departed_zipcode: yup.string().trim().required('Please enter zip code.'),
    departed_city: yup.string().trim().required('Please enter city.'),
    contact: yup.number().typeError('Please select contact person.').required('Please select contact person.'),
    services: yup.array().min(1, 'Services field must have at least 1 items').of(yup.string().required()).required('Please select at least one service.'),
    spouse_name: yup.string().trim(),
    spouse_cprnr: yup.string().trim().nullable().matches(/^(?:(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{2}[-]?\d{4}|)$/gm, 'Please enter valid CPR-Nr.'),
    spouse_departed_date: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    have_will: yup.boolean().required('Please select if deceased have left will.'),
    left_company: yup.string().nullable(),
    company_name: yup.string().nullable(),
    company_cvrnr: yup.string().nullable(),
    company_address: yup.string().nullable()
});

export default agreementSchema;