import React, { useState, useEffect } from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LayoutWithoutHeader from "../../../components/FrontLayout/LayoutWithoutHeader";
import Container from "react-bootstrap/Container";
import styles from "../../../styles/steps.module.scss";
import {get as getAgreement} from "../../../services/Apis/Front/Agreements.service";
import Loading from "../../../components/Loading";
import {update as updateAgreement} from "../../../services/Apis/Front/Agreements.service";

function SignDocument() {

    const navigate = useNavigate();
    let { agreementId } = useParams();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const response = await getAgreement(agreementId);
            setIsLoading(false);
        })();

        document.body.classList.add('white-background');
    }, []);

    const handleFormSubmission = async () => {
        let data = {step: 'sign-document'};

        try {
            const response = await updateAgreement(agreementId, data);
            window.location.replace(response.href);
        } catch (err) {
            alert('Something went wrong! Please try again later');
        }
    }
  return (
      <LayoutWithoutHeader>
          <>
          {isLoading &&
              <Loading/>
          }
          {!isLoading &&
              <>
                  <div className={styles['q-step-row']}>
                      <Container className={styles['container']}>
                          <div className={styles['q-step-small-container']}>
                              <div className={`${styles['q-step-title']} text-left`}>You have to sign agreement.</div>
                              <p className={`text-center`}>Click on below button to sign document.</p>
                              <div className={styles['q-steps-btn']}>
                                  <button type="button" onClick={() => handleFormSubmission()} className={`blue-btn ${styles['blue-btn']}`}>Sign Document</button>
                              </div>
                          </div>
                      </Container>
                  </div>
                  <div className={styles['q-step-footer']}>
                      <Container>
                          <div className={styles['step-back']}>
                              <button
                                  onClick={() => navigate('/aftale/' + agreementId + '/dodsboer-information')}>Tilbage
                              </button>
                          </div>
                      </Container>
                  </div>
              </>
          }
          </>
      </LayoutWithoutHeader>
  );
}

export default SignDocument;
