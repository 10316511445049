import { BrowserRouter, Routes, Route, createBrowserRouter } from "react-router-dom";
import Home from '../pages/Home';
import Agreements from "../pages/Admin/Agreements/Agreements";
import CreateAgreement from "../pages/Admin/Agreements/CreateAgreement";
import AgreementContacts from "../pages/Admin/Agreements/AgreementContacts";
import SignIn from "../pages/Auth/SignIn";
import SignHeirAgreement from "../pages/Signatures/SignHeirAgreement";
import Users from "../pages/Admin/Users/Users";
import CreateUser from "../pages/Admin/Users/CreateUser";
import Start from "../pages/Front/Agreements/Start";
import ContactInformation from "../pages/Front/Agreements/ContactInformation";
import AfdobeInformation from "../pages/Front/Agreements/AfdobeInformation";
import EstateInformation from "../pages/Front/Agreements/EstateInformation";
import HeirsInformation from "../pages/Front/Agreements/HeirsInformation";
import SignDocument from "../pages/Front/Agreements/SignDocument";
import RequireAuth from '@auth-kit/react-router/RequireAuth';
import Dashboard from "../pages/Admin/Dashboard/Dashboard";
import ErrorPageNotFound from "../pages/Errors/ErrorPageNotFound";
import FrontDashboard from "../pages/Front/Dashboard/Dashboard";

const fallbackPath = "/";

const router = createBrowserRouter([
    {
      path: "*",
      element: <ErrorPageNotFound />,
    },
    {
      path: "/",
      element: <SignIn />,
    },
    {
        path: "/dashboard",
        element: <RequireAuth fallbackPath={fallbackPath}><FrontDashboard /></RequireAuth>,
    },
    {
      path: "/aftale/:agreementId/start",
      element: <RequireAuth fallbackPath={fallbackPath}><Start /></RequireAuth>,
    },
    {
      path: "/aftale/:agreementId/kontakt-information",
      element: <RequireAuth fallbackPath={fallbackPath}><ContactInformation /></RequireAuth>,
    },
    {
      path: "/aftale/:agreementId/afdobe-information",
      element: <RequireAuth fallbackPath={fallbackPath}><AfdobeInformation /></RequireAuth>,
    },
    {
      path: "/aftale/:agreementId/dodsboer-information",
      element: <RequireAuth fallbackPath={fallbackPath}><EstateInformation /></RequireAuth>,
    },
    {
      path: "/aftale/:agreementId/sign-document",
      element: <RequireAuth fallbackPath={fallbackPath}><SignDocument /></RequireAuth>,
    },
    {
      path: "/aftale/:agreementId/arvinger-information",
      element: <RequireAuth fallbackPath={fallbackPath}><HeirsInformation /></RequireAuth>,
    },
    {
      path: "/signature/heir/:slug/:contact",
      element: <SignHeirAgreement />,
    },
    {
      path: "/admin/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/admin/agreements",
      element: <Agreements />,
    },
    {
      path: "/admin/agreements/create",
      element: <CreateAgreement />,
    },
    {
      path: "/admin/agreements/:slug",
      element: <CreateAgreement />,
    },
    {
      path: "/admin/agreements/:slug/contacts",
      element: <AgreementContacts />,
    },
    {
      path: "/admin/users",
      element: <Users />,
    },
    {
      path: "/admin/users/create",
      element: <CreateUser />,
    },
    {
      path: "/admin/users/:id/edit",
      element: <CreateUser />,
    }
]);

export default router;