import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LayoutWithoutHeader from "../../../components/FrontLayout/LayoutWithoutHeader";
import Container from "react-bootstrap/Container";
import styles from "../../../styles/steps.module.scss";
import { useSearchParams } from 'react-router-dom';
import {Image} from "react-bootstrap";
import loginImage from "../../../assets/images/login-image.png";
import { get as getAgreement } from '../../../services/Apis/Front/Agreements.service';
import Loading from "../../../components/Loading";

function Start() {

    let { agreementId } = useParams();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const response = await getAgreement(agreementId);
            setIsLoading(false);
        })();

        document.body.classList.add('white-background');
    }, [])

  return (
      <LayoutWithoutHeader>
          <>
              {isLoading &&
                  <Loading/>
              }

              {!isLoading &&
                  <div className={styles['q-step-row']}>
                      <Container className={styles['container']}>
                          <Row>
                              <Col sm={12} md={12}>
                                  <div className={`w-100 text-center`}><Image src={loginImage} width={320} /></div>
                                  <div className={styles['q-step-title']}>Vi ser frem til at hjælpeb dig<br/> med boopgørelse.
                                  </div>
                                  <div className={styles['q-step-small-container']}>
                                      <div className={`${styles['q-step-bullet-box']} ${styles['icon-1']}`}>
                                          <span>Digitalt</span><br/>
                                          Opdel alt mellem familie, venner og endda velgørenhedsorganisationer
                                      </div>
                                      <div className={`${styles['q-step-bullet-box']} ${styles['icon-2']}`}>
                                          <span>Nemt og hurtigt</span><br/>
                                          Giv dine kære noget at huske dig ved
                                      </div>
                                      <div className={`${styles['q-step-bullet-box']} ${styles['icon-3']}`}>
                                          <span>Sikkert</span><br/>
                                          Vi tager dit privatliv alvorligt. Vi vil aldrig sælge dine data, og vores
                                          verdensklasse sikkerhed sikrer at alle dine testemant og andre dokumenter er helt
                                          fortrolig.
                                      </div>
                                      <div className={styles['q-steps-btn']}>
                                          <Link to={"/aftale/" + agreementId + "/kontakt-information"}
                                                className={`blue-btn ${styles['blue-btn']}`}>Super, lad os gøre det</Link>
                                      </div>
                                  </div>
                              </Col>
                          </Row>
                      </Container>
                  </div>
              }
          </>

      </LayoutWithoutHeader>
  );
}

export default Start;
