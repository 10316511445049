import {Link, useLocation} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from './components/Header';

const Layout = ({ children }: any) => {

    const location = useLocation();

    return (
        <>
            <div className="mobile-fix-nav d-lg-none">
                <ul>
                    <li className={ location.pathname == '/admin/dashboard' ? `active` : `` }><Link to={'/admin/dashboard'} className="icon-1"></Link></li>
                    <li className={ (location.pathname == '/agreements' || location.pathname.substring(0, 11) == '/agreements') ? `active` : `` }><Link to={'/agreements'} className="icon-2"></Link></li>
                    <li><a href="#" className="icon-4"></a></li>
                </ul>
            </div>

            <Header />

            <div className="handling-container">
                <Container>
                    <div className="handling-left d-none d-lg-block">
                        <ul>
                            <li className={ location.pathname == '/admin/dashboard' ? `active` : `` }><Link to={'/admin/dashboard'}><span className="icon-1"></span> Dashboard</Link></li>
                            <li className={ (location.pathname == '/admin/agreements' || location.pathname.substring(0, 11) == '/admin/agreements') ? `active` : `` }><Link to={'/admin/agreements'}><span className="icon-2"></span> Aftaler</Link></li>
                            <li className={ (location.pathname == '/admin/users' || location.pathname.substring(0, 11) == '/admin/users') ? `active` : `` }><Link to={'/admin/users'}><span className="icon-2"></span> Users</Link></li>
                            <li><a href="#"><span className="icon-2"></span> Settings</a></li>
                        </ul>
                    </div>
                    <div className="handling-right">
                        <Row>
                            <Col xl={12}>
                                {children}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Layout;
