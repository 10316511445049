import React, { useState, useEffect } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LayoutWithoutHeader from '../../components/FrontLayout/LayoutWithoutHeader';
import Loading from "../../components/Loading";
import {Container} from "react-bootstrap";
import styles from '../../styles/signatures.module.scss';
import Modal from 'react-bootstrap/Modal';
import { get, create } from '../../services/Apis/AgreementContactSignature.service';
import { useParams } from "react-router-dom";
import AgreementContactSignatureSchema from "../../services/Schemas/AgreementContactSignatureSchema";
import IAgreementContactSignature from '../../interfaces/IAgreementContactSignature';

function SignHierAgreement() {

    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [signatureContact, setSignatureContact] = useState<IAgreementContactSignature>();

    const { register, handleSubmit, setValue, formState:{ errors } } = useForm({
        resolver: yupResolver(AgreementContactSignatureSchema)
    });

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const { slug, contact } = useParams();

    const handleSignature = async (data: any) => {
        const response = await create(slug, contact, data);
        window.location.href = response.signature_link;
    }

    const setDefaultValues = async (data: any) => {
        setValue('first_name', data.first_name);
        setValue('last_name', data.last_name);
        setValue('cprnr', data.cprnr);
        setValue('address', data.address);
        setValue('zipcode', data.zipcode);
        setValue('city', data.city);
        setValue('phone_number', data.phone_number);
        setValue('email', data.email);

        setValue('approve_assets_liabilities', data.approve_assets_liabilities);
        setValue('allow_sale_mortgage', data.allow_sale_mortgage);
        setValue('registering_deeds', data.registering_deeds);
        setValue('approve_inventory', data.approve_inventory);
        setValue('transfer_power_of_attorney', data.transfer_power_of_attorney);

    }

    useEffect(() => {
        (async () => {
            const response = await get(slug, contact);
            setIsLoading(false);
            setDefaultValues(response);
            setSignatureContact(response);
        })();
    }, [])

  return (
      <LayoutWithoutHeader>
          <>
              {isLoading &&
                  <Loading/>
              }

              {!isLoading &&
                  <>
                      <Container className={styles['signature-container']}>
                          <div className={styles['white-box']}>
                              {signatureContact?.is_signed === true &&
                                  <>
                                      <h3>Skiftefuldmagt</h3>
                                      <p className={'text-center'}>You have already signed the agreement. Please check your email.</p>
                                  </>
                              }

                              {!signatureContact?.is_signed === true &&
                                  <>

                                      <h3>Skiftefuldmagt</h3>
                                      <p className={'text-left pt-4'}>{ signatureContact?.first_name+" "+signatureContact?.last_name} har i forbindelse med dødsfald af { signatureContact?.departed_person_name }, kontaktet Karva som bobehandler.</p>
                                      <p className={'text-left pt-3'}>For at komme helt i mål med at vi kan blive bobehandler, skal vi også bruge dine oplysninger da du er arving. Vi vil bede dig om at udfylde og underskrive skiftefuldmagten på næste side. Når du har underskrevet skiftefuldmagten, vil vi indsende den sammen med andre relevante dokumenter til skifteretten og få udstedt en skifteretsattest. Herefter kan vi begynde at behandle boet.</p>
                                      <div className={`${styles['signature-form']} w-100 float-left fs-5 p-0`}>
                                          <form onSubmit={handleSubmit(handleSignature)}>
                                              <Row>
                                                  <Col xl={6}>
                                                      <div className={styles['form-group']}>
                                                          <label>Fornavn</label>
                                                          <input type="text" className="form-control fs-5" {...register("first_name")} />
                                                          <div className="invalid-feedback-error">{errors?.first_name?.message}</div>
                                                      </div>
                                                  </Col>

                                                  <Col xl={6}>
                                                      <div className={styles['form-group']}>
                                                          <label>Efternavn</label>
                                                          <input type="text" className="form-control fs-5" {...register("last_name")} />
                                                          <div className="invalid-feedback-error">{errors?.last_name?.message}</div>
                                                      </div>
                                                  </Col>
                                              </Row>
                                              <Row>
                                                  <Col xl={6}>
                                                      <div className={styles['form-group']}>
                                                          <label>CPR-nr.</label>
                                                          <input type="text" className="form-control fs-5 with-icon" {...register("cprnr")} />
                                                          <div className="invalid-feedback-error">{errors?.cprnr?.message}</div>
                                                      </div>
                                                  </Col>

                                                  <Col xl={6}>
                                                      <div className={styles['form-group']}>
                                                          <label>Adresse</label>
                                                          <input type="text" className="form-control" {...register("address")} />
                                                          <div className="invalid-feedback-error">{errors?.address?.message}</div>
                                                      </div>
                                                  </Col>
                                              </Row>
                                              <Row>
                                                  <Col xl={6}>
                                                      <div className={styles['form-group']}>
                                                          <label>Post nr.</label>
                                                          <input type="text" className="form-control fs-5" {...register("zipcode")} />
                                                          <div className="invalid-feedback-error">{errors?.zipcode?.message}</div>
                                                      </div>
                                                  </Col>

                                                  <Col xl={6}>
                                                      <div className={styles['form-group']}>
                                                          <label>By</label>
                                                          <input type="text" className="form-control fs-5" {...register("city")} />
                                                          <div className="invalid-feedback-error">{errors?.city?.message}</div>
                                                      </div>
                                                  </Col>
                                              </Row>
                                              <Row>
                                                  <Col xl={6}>
                                                      <div className={styles['form-group']}>
                                                          <label>Tlf. nr.</label>
                                                          <input type="tel" className="form-control fs-5" {...register("phone_number")} />
                                                          <div className="invalid-feedback-error">{errors?.phone_number?.message}</div>

                                                      </div>
                                                  </Col>

                                                  <Col xl={6}>
                                                      <div className={styles['form-group']}>
                                                          <label>Email</label>
                                                          <input type="email" className="form-control fs-5" disabled={true} {...register("email")} />
                                                          <div className="invalid-feedback-error">{errors?.email?.message}</div>
                                                      </div>
                                                  </Col>
                                              </Row>

                                              <Row>
                                                  <Col xl={12}>
                                                      <div className={styles['form-group']}>
                                                          <div>Følgende rubrikker skal afkrydses såfremt du <strong>IKKE</strong> ønsker, at vi påtager os en specifik opgave. Vi anbefaler, at du ikke krydser nogen rubrik af, da det ellers vil betyde at Karva bruger ekstra tid på at indhente din og andre arvingers underskrift til diverse foranstaltninger. <a className={'fw-bold'} role="button" onClick={(e) => handleShowModal()} >(?)</a></div>
                                                      </div>
                                                      <div className={styles['form-group']}>
                                                          <div className="form-check">
                                                              <input className="form-check-input" type="checkbox" value="" id="approve_assets_liabilities" {...register("approve_assets_liabilities")} />
                                                              <label className="form-check-label" htmlFor="approve_assets_liabilities">
                                                                  1: Underskrive og godkende opgørelse over boets aktiver og passiver pr. dødsdagen
                                                              </label>
                                                          </div>
                                                          <div className="form-check">
                                                              <input className="form-check-input" type="checkbox" value="" id="allow_sale_mortgage" {...register("allow_sale_mortgage")} />
                                                              <label className="form-check-label" htmlFor="allow_sale_mortgage">
                                                                  2 :Træffe bestemmelse om salg, pantsætning eller udlodning til arvinger af boets ejendele
                                                              </label>
                                                          </div>
                                                          <div className="form-check">
                                                              <input className="form-check-input" type="checkbox" value="" id="registering_deeds" {...register("registering_deeds")} />
                                                              <label className="form-check-label" htmlFor="registering_deeds">
                                                                  3: Tinglyse skøder og pantebreve m.v. vedrørende boets aktiver og passiver samt udstede <br />tinglysningsfuldmagt til en anden fysisk eller juridisk person
                                                              </label>
                                                          </div>
                                                          <div className="form-check">
                                                              <input className="form-check-input" type="checkbox" value="" id="approve_inventory" {...register("approve_inventory")} />
                                                              <label className="form-check-label" htmlFor="approve_inventory">
                                                                  4: Underskrive og godkende boopgorelsen
                                                              </label>
                                                          </div>
                                                          <div className="form-check">
                                                              <input className="form-check-input" type="checkbox" value="" id="transfer_power_of_attorney" {...register("transfer_power_of_attorney")} />
                                                              <label className="form-check-label" htmlFor="transfer_power_of_attorney">
                                                                  5: Videregive fuldmagten til en anden person
                                                              </label>
                                                          </div>
                                                      </div>
                                                  </Col>
                                              </Row>

                                              <Row>
                                                  <Col xl={12}>

                                                      <div className={styles['form-group']}>
                                                          <button type={"submit"} className="blue-btn">Underskriv med MitID →</button>
                                                      </div>
                                                  </Col>
                                              </Row>
                                          </form>
                                      </div>
                                  </>
                              }
                          </div>
                      </Container>

                      <Modal show={showModal} onHide={handleCloseModal} centered>
                          <Modal.Header closeButton>
                              <Modal.Title>Skiftefuldmagt Guide</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                              <p>Vi anbefaler, at du ikke krydser nogen rubrik af, da det ellers vil betyde at Karva bruger ekstra tid på at indhente din og andre arvingers underskrift til diverse foranstaltninger.</p>
                              <p className={"pt-2"}><span className={"fw-bold"}>1:</span> Krydser du dette felt af, betyder det, at Karva ikke kan indsende en åbningsstatus uden at du har underskrevet den. Vi anbefaler, at du ikke krydser rubrikken af.</p>
                              <p className={"pt-2"}><span className={"fw-bold"}>2:</span> Krydser du dette felt af, skal du og andre evt. arvinger f.eks. underskrive samtlige dokumenter hos en ejendomsmægler, som kan forlænge processen. Karva vil f.eks. heller ikke kunne udbetale noget af arven til arvingerne inden boet er afsluttet. Vi anbefaler at du ikke krydser rubrikken af.</p>
                              <p className={"pt-2"}><span className={"fw-bold"}>3:</span> I forbindelse med salg af ejendom(me) skal der underskrives diverse dokumenter hos tinglysningsretten. Krydser du dette felt af, skal du og andre arvinger underskrive sådanne dokumenter. Vi anbefaler at du ikke krydser rubrikken af så Karva kan stå for at underskrive skøder og lignende.</p>
                              <p className={"pt-2"}><span className={"fw-bold"}>4:</span> Når boet endeligt skal opgøres, skal en boopgørelse udfyldes og underskrives. Hvis du krydser rubrikken af, vil en boopgørelse underskrives af dig og samtlige arvinger, som både er besværligt og tidskrævende. Vi anbefaler at du ikke krydser rubrikken af. Husk: Karva vil altid sende boopgørelsen til gennemgang til arvingerne, inden den underskrives af os og indsendes til skifteretten.</p>
                              <p className={"pt-2"}><span className={"fw-bold"}>5:</span> Hvis du ikke ønsker, at vi videresender fuldmagten til f.eks. en ejendomsmægler i forbindelse med salg af en ejendom, vil det betyde at alle arvinger skal lave en ny skiftefuldmagt til ejendomsmægleren. Dette vil både kræve mere tid og energi af arvingerne. Vi anbefaler at du ikke krydser rubrikken af.</p>
                          </Modal.Body>
                      </Modal>
                  </>
              }
          </>
      </LayoutWithoutHeader>
  );
}

export default SignHierAgreement;
