import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../../../components/Layout/Layout';
import Loading from "../../../components/Loading";
import {download as downloadAgreement, getAll as getAllAgreements, destroy as destroyAgreement} from '../../../services/Apis/Admin/Agreements.service';
import DataTable from 'react-data-table-component';

function Agreements() {

    const [agreements, setAgreements] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(true);

    const columns = [
        {
            name: 'Navn',
            selector: (row: any) => row.departed_first_name + ' ' + row.departed_last_name,
            width: '22%'
        },
        {
            name: 'Status',
            selector: (row: any) => row.status,
            width: '22%'
        },
        {
            name: 'CPR-Nr.',
            selector: (row: any) => row.departed_cprnr,
        },
        {
            name: 'Arvinger',
            selector: (row: any) => row.heirs,
        },
        {
            name: 'Aftalt beløb',
            selector: (row: any) => row.amount+' DKK',
        },
        {
            name: 'Andet',
            selector: (row: any) => <>
                <Link to={'/admin/agreements/'+row.agreement_id} className={`small-btn edit`} title={'Rediger'}></Link>
                <Link to={'/admin/agreements/'+row.agreement_id+'/contacts'} className={`small-btn user`} title={'Arvinger'}></Link>
                <button type={"button"} onClick={() => deleteAgreement(row.agreement_id)} className={`small-btn delete`} title={'Slet'}></button>
            </>,
        },
    ];

    const deleteAgreement = async (agreementId: string) => {
        const response = await destroyAgreement(agreementId);
        await getAgreements(1);
    }

    const getAgreements = async (page: number) => {
        setIsLoading(true);

        const response = await getAllAgreements(page, perPage);

        setAgreements(response.data.data);
        setTotalRows(response.data.pagination.total);
        setIsLoading(false);
    };

    const handlePageChange = async (page: number) => {
        await getAgreements(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {

    };

    useEffect(() => {
        (async () => {
            getAgreements(1);
        })();
    }, [])

  return (
      <Layout>
          <>
              <Row className={`row-flex row-flex-wrap`}>
                    <Col xl={12}>
                        <div className="arv-top white-box nopad">
                            <div className="h-box-title">Aftalebrev</div>
                            <div className="arv-top-btn"><Link to={'/admin/agreements/create'} className={`blue-btn`}>+ Opret aftale</Link></div>
                        </div>
                    </Col>
              </Row>
              <Row>
                  <Col xl={12}>
                      <div className="white-box arv-form-box">
                          <DataTable
                              title=""
                              columns={columns}
                              data={agreements}
                              progressPending={isLoading}
                              pagination
                              paginationServer
                              paginationTotalRows={totalRows}
                              onChangeRowsPerPage={handlePerRowsChange}
                              onChangePage={handlePageChange}
                              paginationComponentOptions={{noRowsPerPage: true}}
                          />
                      </div>
                  </Col>
              </Row>
          </>
      </Layout>
  );
}

export default Agreements;
