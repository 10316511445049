import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../../../components/Layout/Layout';
import Loading from "../../../components/Loading";

function Dashboard() {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            setIsLoading(false);
        })();
    }, [])

  return (
      <Layout>
          <>
              <Row className={`row-flex row-flex-wrap`}>
                    <Col xl={12}>
                        <div className="arv-top white-box nopad">
                            <div className="h-box-title">Dashboard</div>
                        </div>
                    </Col>
              </Row>

              {isLoading &&
                  <Loading/>
              }

              {!isLoading &&
                  <>

                  </>
              }
          </>
      </Layout>
  );
}

export default Dashboard;
