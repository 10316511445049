import React, { useState, useEffect } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../../../components/Layout/Layout';
import { get as getContactPersons } from '../../../services/Apis/Admin/ContactPerson.services';
import { get as getServices } from '../../../services/Apis/Admin/Services.service';
import { create as createAgreement, get as getAgreement, update as updateAgreement, download as downloadAgreement } from '../../../services/Apis/Admin/Agreements.service';
import Loading from "../../../components/Loading";
import IContactPerson from "../../../interfaces/IContactPerson";
import IService from "../../../interfaces/IService";
import IAgreement from "../../../interfaces/IAgreement";
import AgreementSchema from "../../../services/Schemas/Admin/AgreementSchema";
import { ReactComponent as Logo } from '../../../assets/images/logo-black.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputMask from 'react-input-mask';

function CreateAgreement() {

    const [isLoading, setIsLoading] = useState(true);
    const [contactPersons, setContactPersons] = useState<IContactPerson[]>();
    const [services, setServices] = useState<IService[]>();
    const [checkedServices, setCheckedServices] = useState<boolean[]>();
    const [clickedButton, setClickedButton] = useState('');
    const [spouseDepartedDate, setSpouseDepartedDate] = useState<Date | undefined>();
    const [departedDate, setDepartedDate] = useState<Date | undefined>();

    const navigate = useNavigate();

    const { slug } = useParams();

    const { register, watch, setValue, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(AgreementSchema)
    });

    const handleSpouseDepartedDate = (date: Date | undefined) => {
        setSpouseDepartedDate(date);
        setValue('spouse_departed_date', date);
    }

    const handleDepartedDate = (date: Date) => {
        setDepartedDate(date);
        setValue('departed_date', date);
    }

    const handleDownloadPdf = async (agreement: string, data: any) => {
        let base64String = agreement;
        const fileName = data.contactPerson.first_name + ' ' + data.contactPerson.last_name + '.pdf';

        base64String = "data:application/pdf;base64," + base64String;

        const linkSource = base64String;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const handleAgreement = async (data: any) => {

        data.action = clickedButton;
        let response = null;

        switch (clickedButton) {
            case 'download-pdf':
                response = await downloadAgreement(slug);
                handleDownloadPdf(response?.agreement, data);
                break;
            default:
                if (slug !== undefined) {
                    response = await updateAgreement(slug, data);
                } else {
                    response = await createAgreement(data);
                }
                navigate('/agreements');
                break;
        }
    };

    const handleServices = (position: number) => {
        const updatedCheckedState = checkedServices?.map((item, index) =>
            index === position ? !item : item
        );

        setCheckedServices(updatedCheckedState);
    }

    const setFormValues = (agreement: IAgreement) => {

        let contactPerson = Object.values(agreement.contactPersons).filter(e => e.is_main_contact === true);

        setValue('case_no', agreement.case_no);
        setValue('departed_first_name', agreement.departed_first_name);
        setValue('departed_last_name', agreement.departed_last_name);
        setValue('departed_cprnr', agreement.departed_cprnr);
        setValue('departed_address', agreement.departed_address);
        setValue('departed_zipcode', agreement.departed_zipcode);
        setValue('departed_city', agreement.departed_city);
        setValue('heirs', agreement.heirs);
        setValue('amount', agreement.amount);
        setValue('contact', agreement.contact.id);
        setValue('have_will', agreement.have_will);

        setValue('left_company', agreement.left_company);
        setValue('company_name', agreement.company_name);
        setValue('company_cvrnr', agreement.company_cvrnr === null ? '' : agreement.company_cvrnr);
        setValue('company_address', agreement.company_address);

        setValue('spouse_name', agreement.spouse_name);
        setValue('spouse_cprnr', agreement.spouse_cprnr === null ? '' : agreement.spouse_cprnr);
        setValue('spouse_departed_date', agreement.spouse_departed_date);

        setValue('contactPerson.first_name', contactPerson[0].first_name);
        setValue('contactPerson.last_name', contactPerson[0].last_name);
        setValue('contactPerson.relation', contactPerson[0].relation);
        setValue('contactPerson.cprnr', contactPerson[0].cprnr === null ? '' : contactPerson[0].cprnr);
        setValue('contactPerson.address', contactPerson[0].address);
        setValue('contactPerson.city', contactPerson[0].city);
        setValue('contactPerson.zipcode', contactPerson[0].zipcode);
        setValue('contactPerson.phone_number', contactPerson[0].phone_number);
        setValue('contactPerson.email', contactPerson[0].email);
        setValue('contactPerson.relation', contactPerson[0].relation);

        if (agreement.spouse_departed_date != undefined) {
            setSpouseDepartedDate(new Date(agreement.spouse_departed_date));
        }

        if (agreement.departed_date != undefined) {
            setDepartedDate(new Date(agreement.departed_date));
        }
    }

    useEffect(() => {
        (async () => {
            const response = await getContactPersons();
            const responseServices = await getServices();

            if (slug !== undefined) {
                const responseAgreement = await getAgreement(slug);
                setTimeout(() => {
                    setFormValues(responseAgreement)
                }, 100);
            }

            setIsLoading(false);
            setContactPersons(response);
            setServices(responseServices);
            setCheckedServices(new Array(responseServices.length).fill(true));
        })();
    }, [])

    return (
        <Layout>
            <>
                <Row className={`row-flex row-flex-wrap`}>
                    <Col xl={12}>
                        <div className="arv-top white-box nopad">
                            <div className="h-box-title">Aftalebrev</div>
                        </div>
                    </Col>
                </Row>

                {isLoading &&
                    <Loading/>
                }

                {!isLoading &&
                    <>
                        <form onSubmit={handleSubmit(handleAgreement)}>
                            <Row>
                                <Col xl={6}>
                                    <div className="white-box arv-form-box">
                                        <div className="form-box-middle text-center no-pad">Kontakt person</div>
                                        <div className="hadnling-form">
                                            <div className="form-group">
                                                <label>Fornavn</label>
                                                <input type="text" className="form-control" {...register("contactPerson.first_name")} />
                                                <div className="invalid-feedback-error">{errors?.contactPerson?.first_name?.message}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>Efternavn</label>
                                                <input type="text" className="form-control" {...register("contactPerson.last_name")} />
                                                <div className="invalid-feedback-error">{errors?.contactPerson?.last_name?.message}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>Relation</label>
                                                <select className="form-select" {...register("contactPerson.relation")}>
                                                    <option value={""}>Relation</option>
                                                    <option value={"mor"}>Mor</option>
                                                    <option value={"far"}>Far</option>
                                                    <option value={"soster"}>Søster</option>
                                                    <option value={"bror"}>Bror</option>
                                                    <option value={"datter"}>Datter</option>
                                                    <option value={"son"}>Søn</option>
                                                    <option value={"spouse"}>Ægtefælle</option>
                                                    <option value={"samlever"}>Samlever</option>
                                                    <option value={"bedstemor"}>Bedstemor</option>
                                                    <option value={"bedstefar"}>Bedstefar</option>
                                                    <option value={"barnebarn"}>Barnebarn</option>
                                                    <option value={"oldebarn"}>Oldebarn</option>
                                                    <option value={"tante"}>Tante</option>
                                                    <option value={"onkel"}>Onkel</option>
                                                    <option value={"faetter"}>Fætter</option>
                                                    <option value={"kusine"}>Kusine</option>
                                                    <option value={"nevo"}>Nevø</option>
                                                    <option value={"niece"}>Niece</option>
                                                    <option value={"svigermor"}>Svigermor</option>
                                                    <option value={"svigerfar"}>Svigerfar</option>
                                                    <option value={"svigerson"}>Svigersøn</option>
                                                    <option value={"svigerdatter"}>Svigerdatter</option>
                                                    <option value={"halvsoster"}>Halvsøster</option>
                                                    <option value={"halvbror"}>Halvbror</option>
                                                    <option value={"ven"}>Ven</option>
                                                    <option value={"andet"}>Andet</option>
                                                </select>
                                                <div
                                                    className="invalid-feedback-error">{errors?.contactPerson?.relation?.message}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>CPR-nr.</label>
                                                <InputMask className="form-control with-icon" mask="999999-9999" maskChar={null} {...register("contactPerson.cprnr")} />
                                                <div className="invalid-feedback-error">{errors?.contactPerson?.cprnr?.message}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>Adresse</label>
                                                <input type="text" className="form-control" {...register("contactPerson.address")} />
                                                <div className="invalid-feedback-error">{errors?.contactPerson?.address?.message}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>Post nr.</label>
                                                <input type="text" className="form-control" {...register("contactPerson.zipcode")} />
                                                <div className="invalid-feedback-error">{errors?.contactPerson?.zipcode?.message}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>By</label>
                                                <input type="text" className="form-control" {...register("contactPerson.city")} />
                                                <div className="invalid-feedback-error">{errors?.contactPerson?.city?.message}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>Tlf. nr.</label>
                                                <input type="tel" className="form-control" {...register("contactPerson.phone_number")} />
                                                <div className="invalid-feedback-error">{errors?.contactPerson?.phone_number?.message}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input type="email" className="form-control" {...register("contactPerson.email")} />
                                                <div className="invalid-feedback-error">{errors?.contactPerson?.email?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white-box arv-form-box">
                                        <div className="form-box-middle text-center no-pad">Længstlevende/Tidligere afdøde ægtefælle</div>
                                        <div className="hadnling-form">
                                            <div className="form-group">
                                                <label>Navn</label>
                                                <input type="text" className="form-control" {...register("spouse_name")} />
                                                <div className="invalid-feedback-error">{errors?.spouse_name?.message}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>CPR-nr.</label>
                                                <InputMask className="form-control with-icon" mask="999999-9999" maskChar={null} {...register("spouse_cprnr")} />
                                                <div className="invalid-feedback-error">{errors?.spouse_cprnr?.message}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>Død den</label>
                                                <input type="hidden" className="form-control with-icon" {...register("spouse_departed_date")} />
                                                <DatePicker isClearable className="form-control" selected={spouseDepartedDate} onChange={(date: Date) => handleSpouseDepartedDate(date)} dateFormat={'dd/MM/yyyy'} />
                                                <div className="invalid-feedback-error">{errors?.spouse_departed_date?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row>
                                        <Col xl={12}>
                                            <div className="white-box arv-form-box">
                                                <div className="form-box-middle text-center no-pad">Den afdøde</div>
                                                <div className="hadnling-form">
                                                    <div className="form-group">
                                                        <label>Sags Nr.</label>
                                                        <input type="text" className="form-control" {...register("case_no")} />
                                                        <div className="invalid-feedback-error">{errors.case_no?.message}</div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Fornavn</label>
                                                        <input type="text" className="form-control" {...register("departed_first_name")} />
                                                        <div className="invalid-feedback-error">{errors.departed_first_name?.message}</div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Efternavn</label>
                                                        <input type="text" className="form-control" {...register("departed_last_name")} />
                                                        <div className="invalid-feedback-error">{errors.departed_last_name?.message}</div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>CPR-nr.</label>
                                                        <InputMask className="form-control with-icon" mask="999999-9999" maskChar={null} {...register("departed_cprnr")} />
                                                        <div className="invalid-feedback-error">{errors.departed_cprnr?.message}</div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Gik bort den:</label>
                                                        <input type="hidden" className="form-control with-icon" {...register("departed_date")} />
                                                        <DatePicker className="form-control" selected={departedDate} onChange={(date: Date) => handleDepartedDate(date)} dateFormat={'dd/MM/yyyy'} />
                                                        <div className="invalid-feedback-error">{errors?.departed_date?.message}</div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Adresse</label>
                                                        <input type="text" className="form-control" {...register("departed_address")} />
                                                        <div className="invalid-feedback-error">{errors?.departed_address?.message}</div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Post nr.</label>
                                                        <input type="text" className="form-control" {...register("departed_zipcode")} />
                                                        <div className="invalid-feedback-error">{errors?.departed_zipcode?.message}</div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>By</label>
                                                        <input type="text" className="form-control" {...register("departed_city")} />
                                                        <div className="invalid-feedback-error">{errors?.departed_city?.message}</div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Antal Arvinger</label>
                                                        <input type="number" className="form-control" min={0} {...register("heirs")} />
                                                        <div className="invalid-feedback-error">{errors.heirs?.message}</div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Aftalt beløb:</label>
                                                        <input type="number" className="form-control" min={0} {...register("amount")} />
                                                        <div className="invalid-feedback-error">{errors.amount?.message}</div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Afdøde eftefiod sig testamente:</label>
                                                        <select className="form-select" {...register("have_will")}>
                                                            <option value={'false'}>Nej</option>
                                                            <option value={'true'}>Ja</option>
                                                        </select>
                                                        <div className="invalid-feedback-error">{errors?.have_will?.message}</div>
                                                    </div>
                                                    <div className="multi-checkbox-row">
                                                        <div className="small-bold-title text-center">Aktiver i boet:</div>
                                                        <ul>
                                                            {services?.map((service, index) => (
                                                                <li>
                                                                    <label className="container-checkbox large-green">
                                                                        { service.name }
                                                                        <input
                                                                            {...register("services")}
                                                                            key={service.id}
                                                                            value={service.id}
                                                                            type="checkbox"
                                                                            id={`custom-checkbox-${index}`}
                                                                            onChange={() => handleServices(index)}
                                                                            checked={checkedServices ? checkedServices[index] : false}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        <div className="invalid-feedback-error">{errors.services?.message}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xl={6}>
                                    <div className="white-box arv-form-box pink-bg">
                                        <div className="form-box-middle text-center no-pad">Virksomhedsoplysninger</div>
                                        <div className="hadnling-form">
                                            <div className="form-group">
                                                <label>Efterlod et selskab</label>
                                                <select className="form-select" {...register("left_company")}>
                                                    <option value={""}>Nej</option>
                                                    <option value={'person_left_company'}>Drev ved dødsfaldet personligt ejet virksomhed</option>
                                                    <option value={'first_person_left_company'}>Førstafdøde drev ved dødsfaldet personligt ejet virksomhed</option>
                                                </select>
                                            </div>

                                            { watch('left_company') != '' &&
                                                <>
                                                    <div className="form-group">
                                                        <label>Firma Navn</label>
                                                        <input type="text" className="form-control" {...register("company_name")} />
                                                        <div className="invalid-feedback-error">{errors?.company_name?.message}</div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Firma CVR-nr</label>
                                                        <InputMask className="form-control" mask="99999999" maskChar={null} {...register("company_cvrnr")} />
                                                        <div className="invalid-feedback-error">{errors?.company_cvrnr?.message}</div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Firma Adresse</label>
                                                        <input type="text" className="form-control" {...register("company_address")} />
                                                        <div className="invalid-feedback-error">{errors?.company_address?.message}</div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={6}>
                                    <div className="white-box arv-form-box pink-bg">
                                        <div className="form-box-middle text-center no-pad">Karva kontakt person</div>
                                        <div className="hadnling-form">
                                            <div className="form-group">
                                                <label>Kontakt person</label>
                                                <select className="form-select" {...register("contact")}>
                                                    <option value={""}>Vælg venligst kontakt person</option>
                                                    {contactPersons?.map((contactPerson, index) => (
                                                        <option key={index} value={contactPerson.id}>{contactPerson.name}</option>
                                                    ))}
                                                </select>
                                                <div className="invalid-feedback-error">{errors.contact?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <div className="agreement-content">
                                <div className="agreement-content-bottom">
                                    <div className="left-side">
                                        <button type={"submit"} onClick={() => setClickedButton('save')} className="gray-btn">Gem</button>
                                    </div>
                                    <div className="right-side">
                                        {/*{ slug !== undefined &&*/}
                                        {/*    <button type={"submit"} onClick={() => setClickedButton('download-pdf')} className="blue-btn">↓ Download PDF</button>*/}
                                        {/*}*/}
                                        <button type={"submit"} onClick={() => setClickedButton('save-and-sign')} className="blue-btn">Gem og send til underskrift →</button>
                                    </div>
                                </div>
                                <div className="agreement-content-bottom">
                                    <p className="red-color">Bemærk: Hvis dokumentet allerede er sendt ud til underskrift og du sender det ud igen til underskrift, vil linket til det oprindelige dokument blive deaktiveret, og brugeren vil ikke kunne underskrive det længere.</p>
                                </div>
                            </div>
                        </form>
                    </>
                }
            </>
        </Layout>
    );
}

export default CreateAgreement;
