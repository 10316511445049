import { Link } from "react-router-dom";
import { Container, Dropdown } from 'react-bootstrap';
import { ReactComponent as Logo } from '../../../assets/images/logo-black.svg';
import styles from "../../../styles/_header.module.scss";

const Header = () => {

    return (
        <header className={styles['header']}>
            <Container>
                <div className={`logo ${styles['logo']}`}><Link to={'/'}><Logo /></Link></div>
                <div className="navigation">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">

                        </div>
                    </nav>
                </div>
                <div className={`${styles['user-dropdown']}`}>
                    <Dropdown>
                        <Dropdown.Toggle className={`${styles['btn-secondary']} ${styles['dropdown-toggle']} btn-secondary`} id="dropdown-basic"><span>Sara’s konto</span></Dropdown.Toggle>

                        <Dropdown.Menu className={styles['dropdown-menu']}>
                            <li><Dropdown.Item href="#/action-1">My Profile</Dropdown.Item></li>
                            <li><Dropdown.Item href="#/action-2">Logout</Dropdown.Item></li>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Container>
        </header>
    )
}

export default Header;
