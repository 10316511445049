import * as yup from "yup";

const afdobeInformationSchema = yup.object({
    departed_first_name: yup.string().trim().required("Indtast venligst fornavn."),
    departed_last_name: yup.string().trim().required("Indtast venligst efternavn."),
    departed_date: yup.date('Indtast venligst datoen for borte.').required('Indtast venligst datoen for borte.').typeError('Indtast venligst datoen for borte.'),
    departed_cprnr: yup.string().trim().required('Indtast venligst cprnr.').matches(/^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{2}[-]?\d{4}$/gm, 'Indtast venligst gyldig cprnr.'),
    departed_address: yup.string().trim().required("Indtast venligst adresse."),
    departed_zipcode: yup.string().trim().required("Indtast venligst post nr."),
    departed_city: yup.string().trim().required("Indtast venligst by."),
}).required();

export default afdobeInformationSchema;