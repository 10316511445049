import * as yup from "yup";

const agreementContactSignatureSchema = yup.object({
    first_name: yup.string().required('Please enter first name.'),
    last_name: yup.string().required('Please enter last name.'),
    cprnr: yup.string().required('Please enter CPR-Nr.'),
    address: yup.string().required('Please enter address.'),
    zipcode: yup.string().required('Please enter zip code.'),
    city: yup.string().required('Please enter city.'),
    phone_number: yup.string().required('Please enter phone number.'),
    email: yup.string().trim().required('Please enter email address.').email('Please enter valid email address.'),
    approve_assets_liabilities: yup.boolean(),
    allow_sale_mortgage: yup.boolean(),
    registering_deeds: yup.boolean(),
    approve_inventory: yup.boolean(),
    transfer_power_of_attorney: yup.boolean()
}).required();

export default agreementContactSignatureSchema;