import * as yup from "yup";

const userSchema = yup.object({
    // case_no: yup.string().trim().required('Please enter case no.'),
    id: yup.string().trim(),
    first_name: yup.string().trim().required('Please enter first name.'),
    last_name: yup.string().trim().required('Please enter last name.'),
    email: yup.string().trim().required('Please enter email.'),
    password: yup.string().trim().nullable().transform((v, o) => (o === '' ? null : v)).min(8, "Password must be 8 characters long."),
});

export default userSchema;