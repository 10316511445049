import * as yup from "yup";

const estateInformationSchema = yup.object({
    heirs: yup.number().typeError('Vælg venligst antal arvinger.').required("Vælg venligst antal arvinger."),
    have_will: yup.boolean().typeError('Vælg venligst, om forfader efterlader et testamente.').required("Vælg venligst, om forfader efterlader et testamente."),
    left_company: yup.string().trim().required("Vælg venligst, om forfader efterlader en virksomhed."),
    spouse_details: yup.string().trim().required("Vælg venligst ægtefælleoplysninger."),
    spouse_name: yup.string().nullable(),
    spouse_cprnr: yup.string().nullable(),
    spouse_departed_date: yup.date('Indtast venligst datoen for borte.').nullable().transform((v, o) => (o === '' ? null : v)).typeError('Indtast venligst datoen for borte.'),
    // spouse_name: yup.string().trim().when("spouse_details", {
    //     is: (spouse_details) => spouse_details === 'yes',
    //     then: yup.string().nullable().required('Indtast venligst ægtefælles navn.')
    // }),
    // spouse_cprnr: yup.string().nullable().trim().test("spouse_cprnr_required", "Indtast venligst ægtefælle CPR-nr.", function (value) {
    //     return this.parent.spouse_details !== 'yes' && value !== '';
    // }).transform((v, o) => (o === '' ? null : v)).matches(/^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{2}[-]?\d{4}$/gm, 'Indtast venligst gyldig cprnr.'),
    // spouse_departed_date: yup.date('Indtast venligst datoen for borte.').nullable().transform((v, o) => (o === '' ? null : v)).typeError('Indtast venligst datoen for borte.'),
}).required();

export default estateInformationSchema;