import React, { useState, useEffect } from 'react';
import {Col, Row, Container } from 'react-bootstrap';
import {Link} from "react-router-dom";

function ErrorPageNotFound() {
  return (
      <>
          <Container>
              <div className="d-flex align-items-center justify-content-center vh-100">
                  <Row>
                      <Col md={12} className={`text-center`}>
                          <h1 className={`error-heading`}>404</h1>
                          <h2 className={`text-uppercase pb-3`}>Oops! Nothing was found</h2>
                          <p className={`fs-5`}>The page you are looking for might have been removed had its name changed or is temporarily unavailable. <Link to={"/"}>Return to homepage</Link></p>
                      </Col>
                  </Row>
              </div>
          </Container>
      </>
  );
}

export default ErrorPageNotFound;
