import styles from '../styles/loading.module.scss';
import {Container, Col, Row} from "react-bootstrap";

export default function Loading() {

    return (
        <>
            <Container>
                <Row>
                    <Col xl={12} className={'text-center'}>
                        <div className={`${styles['loading']}`}>
                            <div className={styles['dot']}></div>
                            <div className={styles['dot']}></div>
                            <div className={styles['dot']}></div>
                            <div className={styles['dot']}></div>
                            <div className={styles['dot']}></div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
