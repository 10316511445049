import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LayoutWithoutHeader from "../../../components/FrontLayout/LayoutWithoutHeader";
import afdobeInformationSchema from "../../../services/Schemas/Front/Agreement/AfdobeInformationSchema";
import Container from "react-bootstrap/Container";
import styles from "../../../styles/steps.module.scss";
import { useNavigate } from 'react-router-dom';
import {get as getAgreement} from "../../../services/Apis/Front/Agreements.service";
import IAgreement from "../../../interfaces/IAgreement";
import Loading from "../../../components/Loading";
import InputMask from "react-input-mask";
import {update as updateAgreement} from "../../../services/Apis/Front/Agreements.service";
import DatePicker from 'react-datepicker';

function AfdobeInformation() {

    const navigate = useNavigate();
    let { agreementId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [departedDate, setDepartedDate] = useState<Date | undefined>();

    const { register, watch, setValue, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(afdobeInformationSchema)
    });

    useEffect(() => {
        (async () => {
            const response = await getAgreement(agreementId);
            setTimeout(() => {
                setFormValues(response);
            }, 100);
            setIsLoading(false);
        })();

        document.body.classList.add('white-background');
    }, []);

    const handleDepartedDate = (date: Date) => {
        setDepartedDate(date);
        setValue('departed_date', date);
    }

    const handleFormSubmission = async (data: any) => {
        data.step = 'afdobe';

        try {
            const response = await updateAgreement(agreementId, data);
            navigate('/aftale/'+ agreementId +'/dodsboer-information');
        } catch (err) {
            console.log(err);
        }
    }

    const setFormValues = (agreement: IAgreement) => {
        setValue('departed_first_name', agreement.departed_first_name);
        setValue('departed_last_name', agreement.departed_last_name);
        setValue('departed_cprnr', agreement.departed_cprnr);
        setValue('departed_address', agreement.departed_address);
        setValue('departed_zipcode', agreement.departed_zipcode);
        setValue('departed_city', agreement.departed_city);

        if (agreement.departed_date != undefined) {
            setDepartedDate(new Date(agreement.departed_date));
            setValue('departed_date', new Date(agreement.departed_date));
        }
    }

  return (
      <LayoutWithoutHeader>
          <>
          {isLoading &&
              <Loading/>
          }
          {!isLoading &&
              <>
              <div className={styles['q-step-row']}>
                  <Container className={styles['container']}>
                      <div className={styles['q-step-small-container']}>
                          <form onSubmit={handleSubmit(handleFormSubmission)}>
                              <div className={`${styles['q-step-title']} text-left`}>Informationer på den afdøde (who
                                  dies)
                              </div>
                              <p className={`text-center`}>Duis aute irure dolor in reprehenderit in voluptate velit esse
                                  cillum dolore eu fugiat nulla pariatur</p>
                              <Row>
                                  <Col md={6} sm={12}>
                                      <div className={styles['form-group']}>
                                          <input type="text" className={`form-control ${styles['form-control']}`} placeholder="Fornavn" {...register("departed_first_name")} />
                                          <div className={styles['invalid-field-error']}>{errors?.departed_first_name?.message}</div>
                                      </div>
                                  </Col>
                                  <Col md={6} sm={12}>
                                      <div className={styles['form-group']}>
                                          <input type="text" className={`form-control ${styles['form-control']}`} placeholder="Efternavn" {...register("departed_last_name")} />
                                          <div className={styles['invalid-field-error']}>{errors?.departed_last_name?.message}</div>
                                      </div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md={12} sm={12}>
                                      <div className={styles['form-group']}>
                                          <InputMask className={`form-control ${styles['form-control']}`} mask="999999-9999" maskChar={null} {...register("departed_cprnr")} placeholder="CPR-nr." />
                                          <div className={styles['invalid-field-error']}>{errors?.departed_cprnr?.message}</div>
                                      </div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md={12} sm={12}>
                                      <div className={styles['form-group']}>

                                          <input type="hidden"
                                                 className="form-control" {...register("departed_date")} />
                                          <DatePicker className={`form-control ${styles['form-control']} w-100`} selected={departedDate}
                                                      onChange={(date: Date) => handleDepartedDate(date)}
                                                      dateFormat={'dd/MM/yyyy'} placeholderText={"Gik Bort Dato"} />
                                          <div
                                              className={styles['invalid-field-error']}>{errors?.departed_date?.message}</div>
                                      </div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md={12} sm={12}>
                                      <div className={styles['form-group']}>
                                      <input type="text" className={`form-control ${styles['form-control']}`} placeholder="Adresse" {...register("departed_address")} />
                                          <div className={styles['invalid-field-error']}>{errors?.departed_address?.message}</div>
                                      </div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md={6} sm={12}>
                                      <div className={styles['form-group']}>
                                          <input type="text" className={`form-control ${styles['form-control']}`} placeholder="Post nr" {...register("departed_zipcode")} />
                                          <div className={styles['invalid-field-error']}>{errors?.departed_zipcode?.message}</div>
                                      </div>
                                  </Col>
                                  <Col md={6} sm={12}>
                                      <div className={styles['form-group']}>
                                          <input type="text" className={`form-control ${styles['form-control']}`} placeholder="By" {...register("departed_city")} />
                                          <div className={styles['invalid-field-error']}>{errors?.departed_city?.message}</div>
                                      </div>
                                  </Col>
                              </Row>
                              <div className={styles['q-steps-btn']}>
                                  <button type="submit" className={`blue-btn ${styles['blue-btn']}`}>GEM & FORTSÆT 👉</button>
                              </div>
                          </form>
                      </div>
                  </Container>
              </div>
              <div className={styles['q-step-footer']}>
                  <Container>
                      <div className={styles['step-back']}>
                          <button onClick={() => navigate('/aftale/'+ agreementId +'/kontakt-information')}>Tilbage</button>
                      </div>
                  </Container>
              </div>
          </>
          }
      </>
      </LayoutWithoutHeader>
  );
}

export default AfdobeInformation;
