import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FrontLayout from "../../../components/FrontLayout/Layout";
import Loading from "../../../components/Loading";
import { ReactComponent as UserIcon } from '../../../assets/images/icon-user.svg';

function FrontDashboard() {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            setIsLoading(false);
        })();
    }, [])

  return (
      <FrontLayout>
          <>
              {isLoading &&
                  <Loading/>
              }

              {!isLoading &&
                  <>
                      <div className={`handling-right-top`}>
                          <Row className={`row row-flex row-flex-wrap`}>
                              <Col xl={6} sm={12}>
                                  <div className={`top-box white-box`}>
                                      <div className={`box-icon icon-1`}></div>
                                      <div className={`h-box-title`}>Boet Efter:</div>
                                      <p>Jens Jensen</p>
                                  </div>
                              </Col>
                              <Col xl={6} sm={12}>
                                  <div className={`top-box white-box`}>
                                      <div className={`box-icon icon-2`}></div>
                                      <div className={`h-box-title`}>Antal arvinger</div>
                                      <p>3</p>
                                  </div>
                              </Col>
                          </Row>
                      </div>
                      <div className={`dashboard-middle`}>
                          <div className={`box-column-2-wrp`}>
                              <Row className={`row row-flex row-flex-wrap`}>
                                  <Col xl={8} sm={12}>
                                      <div className={`dashboard-bottom white-box`}>
                                          <div className={`dashboard-bottom-data`}>
                                              <h3>Bobehanlding tidslinje</h3>
                                              <p>Step 1: Dødsfald - Begravelse/bisættelse af den afdøde.</p>
                                              <div className={`progress-box`}><span></span></div>
                                              <div className={`progress-text`}>12,5% gennemført</div>
                                              <div className={`btn-row`}><a href="#" className="blue-btn">Gå til tidslinje</a>
                                              </div>
                                          </div>
                                      </div>
                                  </Col>
                                  <Col xl={4} sm={12}>
                                      <div className={`white-box box-2`}>
                                          <div className={`title`}>Klar til udbetaling</div>
                                          <div className={`box-amount-big mb-4`}>149.428 DKK</div>
                                          <p>Så snart der er et beløb klar til udbetaling, bliver den vist her.</p>
                                          <div className={`box-btn`}><a href="#" className="blue-btn">Få udbetalt nu 💰</a></div>
                                      </div>
                                  </Col>
                              </Row>
                          </div>
                      </div>

                      <div className={`column-box-row`}>
                          <Row className={`row row-flex row-flex-wrap`}>
                              <Col xl={6} sm={12}>
                                  <div className={`column-box white-box`}>
                                      <div className={`box-title`}>Arvinger</div>
                                      <div className={`arvinger-data`}>
                                          <ul>
                                              <li><span className="arvinger-user"><UserIcon /></span> Brian Nielsen<button></button></li>
                                              <li><span className="arvinger-user"><UserIcon /></span> Pia Nielsen<button></button></li>
                                              <li><span className="arvinger-user"><UserIcon /></span> Mikkel Nielsen<button></button></li>
                                          </ul>
                                      </div>
                                  </div>
                              </Col>
                              <Col xl={6} sm={12}>
                                  <div className={`column-box white-box`}>
                                      <div className={`box-title`}>Kalender</div>
                                      <div className={`kalender-data`}>
                                          <ul>
                                              <li>
                                                  <div className="h-box-data-title">Møde med banken</div>
                                                  <div className="h-box-gray-text">08:00 - 09:00 : 20. Maj</div>
                                              </li>
                                              <li>
                                                  <div className="h-box-data-title">Møde med jurist</div>
                                                  <div className="h-box-gray-text">09:30 - 11:00 : 28. Maj</div>
                                              </li>
                                              <li>
                                                  <div className="h-box-data-title">Møde med jurist</div>
                                                  <div className="h-box-gray-text">13:00- 14:00 : 13. Juni</div>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </Col>
                          </Row>
                      </div>
                  </>
              }
          </>
      </FrontLayout>
  );
}

export default FrontDashboard;
