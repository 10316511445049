import axios from "../../lib/axios";

export async function login(data) {
    const response = await axios.post('/auth/login', data);
    return response;
}

export async function user() {
    const response = await axios.get('/user');
    return response.data;
}