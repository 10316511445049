import * as yup from "yup";

const agreementContactSchema = yup.object({
    first_name: yup.string().required('Please enter first name.'),
    last_name: yup.string().required('Please enter last name.'),
    cprnr: yup.string().required('Please enter valid CPR-Nr.').matches(/^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{2}[-]?\d{4}$/gm, 'Please enter valid CPR-Nr.'),
    address: yup.string().required('Please enter address.'),
    zipcode: yup.string().required('Please enter zip code.'),
    city: yup.string().required('Please enter city.'),
    phone_number: yup.string().required('Please enter phone number.'),
    relation: yup.string().required('Please select relation.'),
    email: yup.string().trim().required('Please enter email address.').email('Please enter valid email address.'),
}).required();

export default agreementContactSchema;