import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import { ReactComponent as Logo } from '../../../assets/images/logo-black.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Header = () => {

    return (
        <header>
            <Container>
                <div className="logo">
                    <Link to={'/'}><Logo /></Link>
                </div>
                <div className="navigation">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                                    aria-controls="navbarNavDropdown" aria-expanded="false"
                                    aria-label="Toggle navigation" className="navbar-toggler collapsed"><span
                                className="icon-bar top-bar"></span> <span className="icon-bar middle-bar"></span> <span
                                className="icon-bar bottom-bar"></span></button>
                            <div id="navbarNavDropdown" className="collapse navbar-collapse">
                                <ul className="navbar-nav">
                                    <li><a href="#">Oversigt</a></li>
                                    <li><a href="#" className="">Mit testamente</a></li>
                                    <li><a href="#">Bobehandling</a></li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </Container>
        </header>
    )
}

export default Header;
