import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../../../components/Layout/Layout';
import Loading from "../../../components/Loading";
import { get as getAgreement } from "../../../services/Apis/Admin/Agreements.service";
import IAgreement from "../../../interfaces/IAgreement";
import IContactPerson from "../../../interfaces/IContactPerson";
import AgreementContact from "./components/AgreementContact";
import IAgreementContact from "../../../interfaces/IAgreementContact";
import toast, { Toaster } from 'react-hot-toast';

function AgreementContacts() {

    const [isLoading, setIsLoading] = useState(true);
    const [agreement, setAgreement] = useState<IAgreement>();
    const [contactPersons, setContactPersons] = useState<IAgreementContact[]>([]);

    const { slug } = useParams();

    useEffect(() => {
        (async () => {
            const response = await getAgreement(slug);
            setAgreement(response);
            setContactPersons(response.contactPersons);
            setIsLoading(false);
        })();
    }, []);

    const handleReload = async () => {
        setIsLoading(true);
        const response = await getAgreement(slug);
        setAgreement(response);
        setContactPersons(response.contactPersons);
        setIsLoading(false);
        toast.success('Agreement contact created successfully.');
    }

    const addNewContact = () => {
        const newContact = {} as IAgreementContact;
        setContactPersons([...contactPersons, newContact]);
    }

  return (
      <Layout>
          <>
              <Toaster position="top-right" />

              {isLoading &&
                  <Loading/>
              }

              {!isLoading &&
                  <>
                      <Row className={`row-flex row-flex-wrap`}>
                          <Col xl={12}>
                              <div className="arv-top white-box nopad">
                                  <div className="h-box-title">Antal arvinger: { agreement?.heirs }</div>
                                  <div className="arv-top-btn"><button disabled={contactPersons.length === agreement?.heirs} onClick={() => addNewContact()} type={"button"}><i className="add-icon"></i> Tilføj ny afving</button>
                                  </div>
                              </div>
                          </Col>
                      </Row>
                      <Row className={`row`}>
                          {contactPersons?.map((contactPerson: IAgreementContact, index: number) => (
                              <Col xl={6}>
                                  <AgreementContact handleReload={handleReload} agreement={agreement} values={contactPerson} title={`Arving ` + (index + 1)} />
                              </Col>
                          ))}
                      </Row>
                  </>
              }
          </>
      </Layout>
  );
}

export default AgreementContacts;
