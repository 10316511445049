import React, { useState, useEffect } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {Link, useNavigate, useParams} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../../../components/Layout/Layout';
import { create as createUser, get as getUser, update as updateUser } from '../../../services/Apis/Admin/Users.service';
import Loading from "../../../components/Loading";
import UserSchema from "../../../services/Schemas/Admin/UserSchema";
import IUser from "../../../interfaces/IUser";

function CreateUser() {

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const { id } = useParams();

    const { register, watch, setValue, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(UserSchema)
    });

    const handleUser = async (data: any) => {

        let response = null;

        if (id !== undefined) {
            response = await updateUser(id, data);
        } else {
            response = await createUser(data);
        }
        navigate('/admin/users');
    };

    const setFormValues = (user: IUser) => {
        setValue('first_name', user.first_name);
        setValue('last_name', user.last_name);
        setValue('email', user.email);
    }

    useEffect(() => {
        (async () => {
            if (id !== undefined) {
                const responseUser = await getUser(id);
                setTimeout(() => {
                    setFormValues(responseUser)
                }, 100);
            }

            setIsLoading(false);
        })();
    }, [])

    return (
        <Layout>
            <>
                <Row className={`row-flex row-flex-wrap`}>
                    <Col xl={12}>
                        <div className="arv-top white-box nopad">
                            <div className="h-box-title">Users</div>
                        </div>
                    </Col>
                </Row>

                {isLoading &&
                    <Loading/>
                }

                {!isLoading &&
                    <>
                        <form onSubmit={handleSubmit(handleUser)}>
                            <Row>
                                <Col xl={12}>
                                    <div className="white-box arv-form-box">
                                        <div className="form-box-middle text-center no-pad">{ id !== undefined ? 'Edit' : 'Create' } User</div>
                                        <div className="hadnling-form">
                                            <div className="form-group">
                                                <label>Fornavn</label>
                                                <input type="text"
                                                       className="form-control" {...register("first_name")} />
                                                <div
                                                    className="invalid-feedback-error">{errors?.first_name?.message}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>Efternavn</label>
                                                <input type="text"
                                                       className="form-control" {...register("last_name")} />
                                                <div
                                                    className="invalid-feedback-error">{errors?.last_name?.message}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input type="email" className="form-control" {...register("email")} />
                                                <div className="invalid-feedback-error">{errors?.email?.message}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>Password</label>
                                                <input type="password" className="form-control" {...register("password")} />
                                                <div className="invalid-feedback-error">{errors?.password?.message}</div>
                                            </div>
                                            <div className="form-group pt-4">
                                                <button type={"submit"} className="btn btn-primary me-2">Gem</button>
                                                <Link to={'/users'} className={"btn btn-outline-dark"}>Cancel</Link>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </>
                }
            </>
        </Layout>
    );
}

export default CreateUser;
