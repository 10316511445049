import axios from "../../../lib/axios";

export async function create(data) {
    const response = await axios.post('/admin/agreements', data);
    return response.data;
}

export async function getAll(page, perPage) {
    const response = await axios.get('/admin/agreements?page='+page);
    return response;
}

export async function get(id) {
    const response = await axios.get('/admin/agreements/'+id);
    return response.data;
}

export async function update(id, data) {
    data._method = 'PUT';
    const response = await axios.post('/admin/agreements/'+id, data);
    return response.data;
}

export async function download(slug) {
    const response = await axios.post('/admin/agreements/' + slug + '/download');
    return response.data;
}

export async function destroy(id) {
    let data = {};
    data._method = 'DELETE';
    const response = await axios.delete('/admin/agreements/'+id, data);
    return response.data;
}