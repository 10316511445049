import {Link, useLocation} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ReactComponent as Logo} from "../../assets/images/logo-black.svg";

const LayoutWithoutHeader = ({ children }: any) => {

    const location = useLocation();

    return (
        <>
            <header>
                <Container>
                    <div className="logo">
                        <Link to={'/'}><Logo /></Link>
                    </div>
                </Container>
            </header>

            {children}
        </>
    )
}

export default LayoutWithoutHeader;
