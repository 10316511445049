import Axios from 'axios'

const axios = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
})

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        window.location.href = "/";
    }
    return error;
});

// axios.interceptors.request.use(function (config) {
//
//     const token = localStorage.getItem('_auth');
//
//     if (token != '') {
//         config.headers.Authorization =  'Bearer ' + token;
//         return config;
//     }
// });

export default axios
