import {Link, useLocation} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Header from './components/Header';
import LeftSidebar from "./components/LeftSidebar";

const FrontLayout = ({ children }: any) => {

    const location = useLocation();

    return (
        <>
            <Header />

            <div className={`handling-container`}>
                <Container>
                    <LeftSidebar/>
                    <div className={`handling-right`}>
                        {children}
                    </div>
                </Container>
            </div>
        </>
    )
}

export default FrontLayout;
