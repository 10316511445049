import * as yup from "yup";

const contactInformationSchema = yup.object({
    first_name: yup.string().trim().required("Indtast venligst fornavn."),
    last_name: yup.string().trim().required("Indtast venligst efternavn."),
    phone_number: yup.string().trim().required("Indtast venligst telefonnummer."),
    email: yup.string().trim().required('Indtast venligst e-mailadresse.').email('Indtast venligst en gyldig e-mailadresse.'),
    relation: yup.string().trim().required("Vælg venligst relation."),
    cprnr: yup.string().trim().required('Indtast venligst cprnr.').matches(/^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{2}[-]?\d{4}$/gm, 'Indtast venligst gyldig cprnr.'),
    address: yup.string().trim().required("Indtast venligst adresse."),
    zipcode: yup.string().trim().required("Indtast venligst post nr."),
    city: yup.string().trim().required("Indtast venligst by."),
}).required();

export default contactInformationSchema;